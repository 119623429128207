import { API_CONFIG } from "../../shared/api/config";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    // loginUrl: BASE_URL + 'core/auth/login',
    verifyTokenUrl: BASE_URL + 'core/auth/verify_token',
};

export const LOGIN_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'API-KEY': API_CONFIG.API_KEY,
};


export const verifyToken = (token: string) => {
    const data = { token };
    const requestHeaders = { ...LOGIN_HEADERS };
    const URL = API_URLS.verifyTokenUrl;
    return fetch(URL, {
        headers: { ...requestHeaders, },
        method: 'POST',
        body: JSON.stringify(data),
    });
};