import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useParams,Redirect, useRouteMatch } from "react-router-dom";
import { Theme, createStyles, makeStyles, fade } from '@material-ui/core/styles';
import { Paper, Divider, Container,Typography, CircularProgress } from '@material-ui/core';

import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import NavBar from '../components/NavBar/NavBar';
import { ErrorStatusBar } from '../../../shared/components';
import { ReferenceCatalogPaths } from '../../Routes/Routes';
import { useLocation, useHistory } from 'react-router-dom';
import { Home, ProductDetailScreen, BrandDetailScreen, CategoryDetailScreen, CompanyDetailScreen, ParentCategoryDetailScreen } from '../../screens';
import debounce from 'lodash/debounce';
import { verifyToken, LOGIN_HEADERS } from '../../api/auth';
import { saveUserLoginData } from '../../data/actions';
import throttle from 'lodash/throttle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    componentroot: {
      display: 'flex',
      // overflow: 'hidden',
    },
    content: {
      height: '100%',
      overflow: 'auto',
      flexGrow: 1,
      marginTop: '64px',
      // backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

// interface Props {
//   onSearch: (event) => void;
//   clearSearch: () => void;
//   searchTerm: string;
//   placeholder?: string;
//   saveUserData: (data: any) => void;
// }

// const regex = /([0-9a-zA-Z]|[.]|[,]|[']|["]|[-]|[_]|[#]|[^%][^;])+$/;

const ReferenceCatalogLayout = (props: any) => {
  const classes = useStyles({});
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [isAuthorized, setAuthorized] = useState(false);
  const [isSupplier, setSupplier]=useState(false)
  const [authorizing, setAuthorizing] = useState(false);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const { token, path, searchterm } = useParams<{ token: string, path: string, searchterm: string; }>();
  const [businessId, setBusinessId]= useState(null)
  const [crumbs, setCrumbs] = useState([])
  const [selectedCrumb, setSelectedCrumb]= useState() as any;
  const navigate = (path) => { history.push(path); };

  const onCrumbsData =(data: any)=>{
    setCrumbs(data)
  }

  const onSelectCrumb=(data: any)=>{
    setSelectedCrumb(data)
  }


  useEffect(() => {
    // console.log("test")
    setAuthorized(false);
    setAuthorizing(true);
    verifyToken(token)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        if(data.business.is_supplier){
          setAuthorizing(false);
          const authHeaders = { ...LOGIN_HEADERS, 'USER-AUTH-TOKEN': data.auth_token };
          props.saveUserData(data);
          try {
            localStorage.setItem('headers', JSON.stringify(authHeaders));
          } catch (e) {
            // saving error
            console.log('error in localStorage');
          }
          setAuthorized(true);
          setVerificationComplete(true);
          setSupplier(true)
          setBusinessId(data.business.id)
        }
        else{
          setAuthorized(false)
          setVerificationComplete(true);
          setSupplier(false)
        }
        
      })
      .catch((errorData: any) => {
        console.log('sign-in error', errorData);
        setAuthorizing(false);
        setAuthorized(false);
        setVerificationComplete(true);
        setSupplier(false)
      });
  }, []);
  
  const onLogoClick = () =>{
    if (crumbs) {
      crumbs.length > 0 ? setCrumbs([]) : null
      const homeUrl = '/reference-catalog/' + `${props.userData.authData.auth_token}`;
      navigate(homeUrl)
    }
  
  }

  
 
  const getRoute = () => {
    // console.log("SearchTerm", searchTerm);
    return (
      <div className={classes.componentroot}>
          <NavBar
            // onSearch={onSearch}
            // searchTerm={searchTerm}
            // clearSearch={clearSearch}
            crumbsData={crumbs}
            selectedCrumb={onSelectCrumb}
            authData={props.userData}
            logoClick={onLogoClick}
          />
       
        <main className={classes.content}>
          <Container>
            <Switch>
              <Route
                exact
                path={ReferenceCatalogPaths.home}
                render={() => (
                  <Home
                    // searchTerm={searchTerm}
                    // searchTerm={delayedSearchTerm}
                    history={history}
                    // clearSearch={clearSearch}
                    location={location}
                    match={match}
                    crumbsData={onCrumbsData}
                    selectedCrumb={selectedCrumb}
                  />
                )}
              />
              <Route
                exact
                path={ReferenceCatalogPaths.productDetail}
                render={() => (
                  <ProductDetailScreen
                    // searchTerm={searchTerm}
                    // searchTerm={delayedSearchTerm}
                    history={history}
                    // clearSearch={clearSearch}
                    location={location}
                    match={match}
                    crumbsData={onCrumbsData}
                    selectedCrumb={selectedCrumb}
                  />
                )}
              />
              <Route
                exact
                path={ReferenceCatalogPaths.brandDetail}
                render={() => (
                  <BrandDetailScreen  
                    // searchTerm={searchTerm}
                    // searchTerm={delayedSearchTerm}
                    history={history}
                    // clearSearch={clearSearch}
                    location={location}
                    match={match}
                    crumbsData={onCrumbsData}
                    selectedCrumb={selectedCrumb}
                  />
                )}
              />
              <Route
                exact
                path={ReferenceCatalogPaths.categoryDetail}
                render={() => (
                  <CategoryDetailScreen
                    // searchTerm={searchTerm}
                    // searchTerm={delayedSearchTerm}
                    history={history}
                    // clearSearch={clearSearch}
                    location={location}
                    match={match}
                    crumbsData={onCrumbsData}
                    selectedCrumb={selectedCrumb}
                  />
                )}
              />
              <Route
                exact
                path={ReferenceCatalogPaths.brandCompanyDetail}
                render={() => (
                  <CompanyDetailScreen
                    // searchTerm={searchTerm}
                    // searchTerm={delayedSearchTerm}
                    history={history}
                    // clearSearch={clearSearch}
                    location={location}
                    match={match}
                    crumbsData={onCrumbsData}
                    selectedCrumb={selectedCrumb}
                  />
                )}
              />
               <Route
                exact
                path={ReferenceCatalogPaths.parentCategoryDetail}
                render={() => (
                  <ParentCategoryDetailScreen
                    // searchTerm={searchTerm}
                    // searchTerm={delayedSearchTerm}
                    history={history}
                    // clearSearch={clearSearch}
                    location={location}
                    match={match}
                    crumbsData={onCrumbsData}
                    selectedCrumb={selectedCrumb}
                  />
                )}
              />
            </Switch>
          </Container>
        </main>
      </div>
    );
  };


  return (
    <div className={classes.root}>
      {verificationComplete &&
        isAuthorized &&
        !authorizing &&
        isSupplier &&
        getRoute()}
      {authorizing && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <CircularProgress color="primary" />
          {/* <div style={{ padding: '16px' }}>Authorizing...</div> */}
        </div>
      )}
      {verificationComplete && !isAuthorized && !authorizing && (
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <Typography variant="h4">403 FORBIDDEN</Typography>
        </div>
      )}
      {verificationComplete && isAuthorized && !authorizing && !isSupplier && (
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <Typography variant="h4">Sorry Invalid Credentails!</Typography>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveUserData: (data: any) => dispatch(saveUserLoginData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceCatalogLayout);