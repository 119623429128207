import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  supplierLinkedMpUrl: BASE_URL + 'korona/master/reference-catalog/linked-mp-list/',
  supplierLinkedMpBrandsUrl: BASE_URL + 'korona/master/reference-catalog/linked-mp-brands-list/',
  supplierLinkedMpCategoriesUrl: BASE_URL + 'korona/master/reference-catalog/linked-mp-categories-list/',
  supplierLinkedMpCompaniesUrl: BASE_URL + 'korona/master/reference-catalog/linked-mp-companies-list/',

  productDetailUrl : BASE_URL + 'korona/master/product-detail-reference-catalog',
  brandDetailUrl: BASE_URL + 'korona/master/brand-detail-reference-catalog',
  categoryDetailUrl: BASE_URL + 'korona/master/category-detail-reference-catalog',
  companyDetailUrl: BASE_URL + 'korona/master/company-detail-reference-catalog',
  parentCategoryDetailUrl : BASE_URL + 'korona/master/parent-category-detail-reference-catalog'
};

export const getSupplierLinkedMps = (
  business_id: number | string,
  page: number | null,
  pageSize: number | null,
  // searchTerm?: string | null,
  orderBy?: string | number,
  // filters?: any,
  // shouldExport?: boolean

) => {
  let URL = `${API_URLS.supplierLinkedMpUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
};


export const getSupplierLinkedMpBrands = (
  business_id: number | string,
  page: number | null,
  pageSize: number | null,
  orderBy?: string | number,
) => {
  let URL = `${API_URLS.supplierLinkedMpBrandsUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
};


export const getSupplierLinkedMpCategories = (
  business_id: number | string,
  page: number | null,
  pageSize: number | null,
  orderBy?: string | number,
) => {
  let URL = `${API_URLS.supplierLinkedMpCategoriesUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
};

export const getSupplierLinkedMpCompanies = (
  business_id: number | string,
  page: number | null,
  pageSize: number | null,
) => {
  const URL = `${API_URLS.supplierLinkedMpCompaniesUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
  return composeRequest(URL, 'GET', null);
};


export const getMasterProductDetail = (
  mp_id: number, 
  business_id: number | string
) => {
  let URL = `${API_URLS.productDetailUrl}?id=${mp_id}&business_id=${business_id}`;
  return composeRequest(URL, 'GET', null);
}


export const getBrandDetail = (
  brand_id: number, 
  business_id: number | string
) => {
  let URL = `${API_URLS.brandDetailUrl}?id=${brand_id}&business_id=${business_id}`;
  return composeRequest(URL, 'GET', null);
}


export const getCategoryDetail = (
  commodity_uuid: any, 
  business_id: number | string
) => {
  let URL = `${API_URLS.categoryDetailUrl}?uuid=${commodity_uuid}&business_id=${business_id}`;
  return composeRequest(URL, 'GET', null);
}


export const getBrandCompanyDetail = (
  company_id: number, 
  business_id: number | string
) => {
  let URL = `${API_URLS.companyDetailUrl}?id=${company_id}&business_id=${business_id}`;
  return composeRequest(URL, 'GET', null);
}


export const getParentCategoryDetail = (
  commodity_uuid: any, 
  business_id: number | string
) => {
  let URL = `${API_URLS.parentCategoryDetailUrl}?uuid=${commodity_uuid}&business_id=${business_id}`;
  return composeRequest(URL, 'GET', null);
}
