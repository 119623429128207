import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';

import { PaginatedResponse } from '../../../shared/models';
import { getBrandDetail } from '../../api/Api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ConfirmModal } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import _isEqual from 'lodash/isEqual';
import {
  FormControl, Dialog, IconButton, Toolbar, AppBar, Input, ListSubheader,
  LinearProgress, Button, Container, Paper, List, ListItemText, ListItem,CardMedia, Divider,
  Card, CardContent, CircularProgress, Typography, Grid,  Slide, Link
} from '@material-ui/core/';
import { blue, orange, red } from '@material-ui/core/colors';
import _filter from 'lodash/filter';
import { ToastType } from '../../../shared/models';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
  ERROR_404
} from '../../../shared/lib/Localization';
import { Image} from '../../components'

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content:{
      margin:'6px!important',
      width:'100%'
    },
    mainCard:{
      minHeight:'89vh',
      placeContent:'stretch center',
      alignItems:'stretch',
      flexDirection:'row'
    },
    brandDataContent:{
      width:'100%',

    },
    metaData:{
      flexDirection:'row',
      display:'flex',
      placeContent:'center',
      alignItems:'center',
      justifyContent:'space-between'
    },
    divider: {
      margin:'30px auto',
      height:'1px',
      width:'100%',
      backgroundColor:'#d3d3d3',
    },
    grid: {
      flexGrow: 1,
      padding:'10px',
      flexFlow:'row wrap',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      marginBottom:'10px',
      marginTop:'10px',

    },
    panelCardroot:{
      display: 'flex',
      width:'300px',
      height:'170px',
      // border: `4px solid ${theme.palette.grey['300']}`,
      transition: "transform 0.15s ease-in-out",
      "&:hover": { 
        boxShadow:`0 6px 6px -3px rgb(0 0 0 / 20%), 
          0 10px 14px 1px rgb(0 0 0 / 14%),
          0 4px 18px 3px rgb(0 0 0 / 12%)`,
        // cursor: 'pointer',
      },
    },
    panelCardImage:{
      height:'150px',
      width: '200px',
      marginLeft:'10px',
      alignSelf:'center',
      "&:hover": { 
        cursor: 'pointer',
      }
    },
    panelCardDetails:{
      display: 'flex',
      flexDirection: 'column',
      alignSelf:'center',
      width:'197px'
    },
    panelCardContent:{
      flex: '1 0 auto',
      opacity: '0.8',
      textAlign:'center'
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },

    panelCommodityGroupLink:{
      fontSize: '0.65rem', 
      opacity: '0.5',
      "&:hover": { 
        cursor: 'pointer',
        textDecoration:'underline'
      },
    },

    appBar: {
      position: 'relative',
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    moreLink:{
      fontSize: '0.75rem', 
      padding:'10px',
      display:'flex',
      justifyContent:'flex-end',
      marginRight:'10px',
      "&:hover": { 
        cursor: 'pointer',
        textDecoration:'underline'
      },
    },

    brandCompanyLink:{
      placeContent: 'stretch center', 
      color:GlobalStyles.secondary, 
      display: 'flex', 
      alignItems: 'stretch', 
      flexDirection: 'row',
      marginBottom: '30px',
      "&:hover": { 
        cursor: 'pointer',
        textDecoration:'underline'
      },
    },

  })
)

interface Props {
  searchTerm: string | undefined;
  userData: any;
  history: any;
  clearSearch: () => void;
  location: any;
  match: any;
}



const BrandDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [brandData, setBrandData] = useState({ ...props.location.state });
  const [expanded, setExpanded] = React.useState(false);
  const [brandProducts, setBrandProducts]=useState([])
  const[isLoading, setLoading]=useState(false)
  const [allProducts, setAllProducts]=useState([])
  const [renderAllProducts, setRenderAllProducts]= useState(false)
  const [showCrumbs, setShowCrumbs]= useState(false)
  const [moreButtonClicked, setMoreButtonClicked]= useState(false)
  const [noProductsFound,setNoProductsFound] = useState(false)
  
  const [mediaUrls, setMediaUrls] = useState([])
  const [isMediaLoading, setMediaLoading] = useState(false)
  const [mediaData, setMediaData] = useState([])
  const [userSelectedMedia, setUserSelectedMedia] = useState(null)
  const [showDefaultMedia, setShowDefaultMedia] = useState(false);
  const [defaultMediaData, setDefaultMediaData] = useState(null as any)
  const [inActiveMedia, setInActiveMedia] = useState([])


  const authId = props.userData.authData.auth_token
  const businessId = props.userData.authData.business.id

  useEffect(() => {
    console.log("props", props)
   },[props])

  useEffect(() => {
    if (props.location && props.location.state) {
      if (props.location.state.id) {
        setLoading(true);
        getBrandDetail(props.location.state.id, businessId)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(data => {
            // props.setPageLoader(false);
            setLoading(false)
            setBrandData(data.brand_details[0])
            setBrandProducts(data.brand_products.slice(0,9))
            setAllProducts(data.brand_products)
            setShowCrumbs(true)
            if(data.brand_details[0].image_data){
              setMediaData(data.brand_details[0].image_data)
              setShowDefaultMedia(false)
            }
            else{
              setShowDefaultMedia(true)
            }
          })
          .catch((error: any) => {
            // console.log("error-------",error)
            // props.setPageLoader(false);
            let msg = 'Failed to fetch Brand Details.';
            if (error && error.status) {
              if (error.status === 403 || error.status === 401) {
                msg = NO_PERMISSIONS_ERROR_MSG;
              } else if (error.status === 500) {
                msg = ERROR_500;
              }
              else if(error.status === 404){
                msg = ERROR_404
                setNoProductsFound(false)
              }

            }
            setLoading(false)
            props.showToast({
              message: msg,
              options: ToastError
            });
            setShowCrumbs(false)
          });
      }
    }

  },[props.location.state])


  useEffect(()=>{
    if (showCrumbs && !moreButtonClicked) {
      brandData.company ? brandData.mp_brand_company ?
        props.crumbsData([
          {
            'id': 1,
            'label': `${brandData.mp_brand_company ? brandData.mp_brand_company.name : ''}`,
            'type': 'company'
          },
          {
            'id': 2,
            'label': `${brandData.name}`,
            'type': 'category',
            'selected': true
          },
        ]) :
        props.crumbsData([
          {
            'id': 1,
            'label': `${brandData.name}`,
            'type': 'brand',
            'selected': true
          },
        ]) :
        props.crumbsData([
          {
            'id': 1,
            'label': `${brandData.name}`,
            'type': 'brand',
            'selected': true
          },
        ])

      // console.log("SELECTED-CRUMB", props.selectedCrumb)
    }
    if (!showCrumbs && moreButtonClicked) {
      brandData.company ? brandData.mp_brand_company ?
      props.crumbsData([
        {
          'id': 1,
          'label': `${brandData.mp_brand_company ? brandData.mp_brand_company.name : ''}`,
          'type': 'company'
        },
        {
          'id': 2,
          'label': `${brandData.name}`,
          'type': 'brand',
        },
        {
          'id': 3,
          'label': `All Products`,
          'type': 'all-products',
          'selected': true
        },

      ]):
      props.crumbsData([
        {
          'id': 1,
          'label': `${brandData.name}`,
          'type': 'brand',
        },
        {
          'id': 2,
          'label': `All Products`,
          'type': 'all-products',
          'selected': true
        },
      ]) : null

    }
  },[showCrumbs, moreButtonClicked])


  useEffect(()=>{
    if(props.selectedCrumb){
      if(props.selectedCrumb.type === 'company'){
        navigateToCompanyDetailScreen(brandData.mp_brand_company)
      }
      if(props.selectedCrumb.type === 'brand'){
        navigateToBrandDetailScreen(brandData)
      }
    }
  },[props.selectedCrumb])


  useEffect(() => {
    let defaultImage;
    let inactiveImageUrls;
    if (mediaData.length > 0) {

      let urls = mediaData.map(i => i.media_core ? i.media_core.source : '');
      setMediaUrls(urls)
      // console.log("IMAGE-URLS", urls)

      defaultImage = mediaData.filter(i => i.default === true)
      setDefaultMediaData(defaultImage)
      setUserSelectedMedia(mediaData ? mediaData[0].media_core.source : null)

      // console.log("Default Image", defaultImage)

      inactiveImageUrls = mediaData.filter(i => i.is_inactive === true)
      setInActiveMedia(inactiveImageUrls)
      // console.log("INACTIVE-IMAGES", inactiveImageUrls)

    }
  }, [mediaData])

  const getDefaultImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };


  const navigateToBrandDetailScreen = (data: any)=>{
    const brandDetailUrl = '/reference-catalog/' + `${authId}` + '/brands/detail/'  + `${data.id}`;
    // console.log(brandDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) :  null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false): null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(brandDetailUrl, data);
  }

  const navigateToProductDetailScreen = (data: any)=>{
    const productDetailUrl = '/reference-catalog/' + `${authId}` + '/product/'  + `${data.master_product.id}`;
    // console.log(productDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) :  null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false): null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(productDetailUrl, data);
  }


  const navigateToCategoryDetailScreen = (data: any)=>{
    // console.log("CATEGORYDETAILLLL-BRAND", data)
    const categoryDetailUrl = '/reference-catalog/' + `${authId}` + '/categories/detail/'  + `${data.uuid}`;
    // console.log(categoryDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) :  null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false): null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(categoryDetailUrl, data);
  }

  const navigateToCompanyDetailScreen = (data: any)=>{
    const companyDetailUrl = '/reference-catalog/' + `${authId}` + '/brands/companies/detail/'  + `${data.id}`;
    // console.log(productDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) :  null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false): null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(companyDetailUrl, data);
  }


  const handlePanelProductImageOnClick = (data: any) => {
    if (data) {
      navigateToProductDetailScreen(data)
    }
  }

  const handlePanelBrandOnClick = (data: any) => {
    if (data) {
      navigateToBrandDetailScreen(data)
    }

  }

  const handlePanelCategoryOnClick = (data: any) => {
    if (data) {
      navigateToCategoryDetailScreen(data)
    }
  }


  const handleClickOpen = () => {
    setRenderAllProducts(true)
    setMoreButtonClicked(true)
    setShowCrumbs(false)
  };

  const handleSelectedMedia = (data: any) => {
    setUserSelectedMedia(data)
  }


  const getImagePath=(productData: any)=>{
    if (productData){
      if(productData.image_data && productData.image_data.length > 0){
        let defaultImg = productData.image_data.filter(i=>i.default === true)
        return defaultImg && defaultImg.length ===1 ? defaultImg[0].media_core.source : null
      }
      else if(productData.brand && productData.brand.media_anchor && productData.brand.image_data && productData.brand.image_data.length > 0){
        let defaultImg = productData.brand.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      else if(productData.brand && productData.brand.company && productData.brand.mp_brand_company.media_anchor && productData.brand.mp_brand_company.image_data && productData.brand.mp_brand_company.image_data.length > 0 ){
        let defaultImg = productData.brand.mp_brand_company.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getDefaultImagePath()
    }
  }


  const getBrandProducts = () => {
    return brandProducts.map((p, index) => {
      return (
        <Grid key={index} item>
          <Card 
            className={classes.panelCardroot} 
            key={index}
          >
            <CardMedia
              className={classes.panelCardImage}
              image={getImagePath(p.master_product)}
              onClick={()=>handlePanelProductImageOnClick(p)}
              style={{ backgroundSize: 'contain' }}
            />
            <div className={classes.panelCardDetails}>
              <CardContent className={classes.panelCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                  {p.master_product.name ? p.master_product.name : ''}
                </Typography>
                <Typography style={{ fontSize: '0.75rem', marginBottom:'5px', marginTop:'5px' }} variant='body2'>
                  {p.master_product.brand ? p.master_product.brand.name : ''}
                </Typography>
                <Typography 
                  className={classes.panelCommodityGroupLink} 
                  variant='body1'
                  onClick={()=>handlePanelCategoryOnClick(p.business_product.commodityGroup)} 
                >
                  {p.business_product.commodityGroup ? p.business_product.commodityGroup.name ? p.business_product.commodityGroup.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      )
    })
  }


  const getMetaData =()=>{
    return(
      <div className={classes.metaData} >
        <div style={{ marginRight: '150px', display: 'block' }}>
          <Typography variant='h2' style={{ marginBottom: '30px', textAlign: 'center',color:GlobalStyles.primaryColor }}>
            {brandData.name ? brandData.name : ''}
          </Typography>
          <Typography 
            variant='h4' 
            className={classes.brandCompanyLink}
            onClick={()=>navigateToCompanyDetailScreen(brandData.mp_brand_company )}
            style={{ backgroundSize: 'contain' }}
            >
            {brandData.company ? brandData.mp_brand_company ? brandData.mp_brand_company.name : '' : ''}
          </Typography>
        </div>
        <div>
          {brandData.imageData && brandData.imageData.length > 0 ?
            <Image
              itemData={brandData ? brandData : null}
              mediaUrls={mediaUrls ? mediaUrls.length > 0 ? mediaUrls : null : null}
              mediaData={mediaData ? mediaData.length > 0 ? mediaData : null : null}
              showDefaultMedia={showDefaultMedia ? showDefaultMedia : null}
              defaultMediaData={defaultMediaData ? defaultMediaData : null}
              handleSelectedMedia={handleSelectedMedia}
              userSelectedMedia={userSelectedMedia ? userSelectedMedia : null}
            /> :
            <img
              alt={brandData.name ? brandData.name : ''}
              src={getImagePath(brandData)}
              style={{ objectFit:'contain', height: '225px', width: '225px' }}
            />
          }
        </div>
      </div>
    )
  }


  const getCategoryStory=()=>{
    return(
      <div>
        <div style={{placeContent:'center', display:'flex',alignItems:'center',flexDirection:'row',}}>
          <Typography variant='h5' style={{marginBottom:'16px'}}>
            Our Story
          </Typography>
        </div>
        <div style={{marginBottom:'16px', display:'block'}}>
          <Typography style={{ fontSize: '14px' }}>
            {/* Description of the BRAND  */}
          </Typography>
        </div>
      </div>
    )
  }

  const getAllProducts = () => {
    return allProducts.map((p, index) => {
      return (
        <Grid key={index} item>
        <Card 
          className={classes.panelCardroot} 
          key={index}
        >
          <CardMedia
            className={classes.panelCardImage}
            image={getImagePath(p.master_product)}
            onClick={()=>handlePanelProductImageOnClick(p)}
            style={{ backgroundSize: 'contain' }}
          />
          <div className={classes.panelCardDetails}>
            <CardContent className={classes.panelCardContent}>
              <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                {p.master_product.name ? p.master_product.name : ''}
              </Typography>
              <Typography style={{ fontSize: '0.75rem', marginBottom:'5px', marginTop:'5px' }} variant='body2'>
                {p.master_product.brand ? p.master_product.brand.name : ''}
              </Typography>
              <Typography 
                className={classes.panelCommodityGroupLink} 
                variant='body1'
                onClick={()=>handlePanelCategoryOnClick(p.business_product.commodityGroup)} 
              >
                {p.business_product.commodityGroup ? p.business_product.commodityGroup.name ? p.business_product.commodityGroup.name : '' : ''}
              </Typography>
            </CardContent>
          </div>
        </Card>
      </Grid>
      )
    })
  }




  const renderMoreProducts = () => {
    return (
      <div>
          <Paper elevation={0} >
            <Grid container className={classes.grid} item xs={12} spacing={2}>
              {getAllProducts()}
            </Grid>
          </Paper>
      </div>
    )
  }


  return (
    <div >
      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading &&
        <div>
          <Paper elevation={3}  square={false}  className={classes.content} >
          <Container style={{paddingBottom:'18px', paddingTop:'10px'}}fixed >
            {
              !renderAllProducts &&
              <div className={classes.mainCard}>
                <div className={classes.brandDataContent}>
                  <div style={{ display: 'block' }}>
                    <div >
                      {getMetaData()}
                    </div>
                    <Divider className={classes.divider} />
                    {/* <div>   //Description of the Brand Comes here.
                    {getCategoryStory()}
                  </div> */}
                    <div>
                      <Paper elevation={0}>
                        <div>
                          <div style={{ display: 'flex', placeContent: 'center', flexDirection: 'row', alignItems: 'center', padding: '10px' }}>
                            <Typography variant='h5' style={{ marginBottom: '16px', color: GlobalStyles.primaryColor }}>
                              Products
                            </Typography>
                          </div>
                            {!noProductsFound &&
                              <div >
                                <Grid container className={classes.grid} item xs={12} spacing={2}>
                                  {getBrandProducts()}
                                </Grid>
                                {
                                  allProducts.length > 9 &&
                                  <div
                                    className={classes.moreLink}
                                    onClick={handleClickOpen}
                                  >
                                    <Typography style={{ color: GlobalStyles.primaryColor }}>
                                      {`More >>`}
                                    </Typography>
                                  </div>
                                }
                              </div>
                            }
                            {noProductsFound &&
                              <div style={{ display: 'flex', placeContent: 'center', flexDirection: 'row', alignItems: 'center', padding: '10px' }}>
                                <Typography variant="h5" style={{ color: GlobalStyles.warningText }}>
                                  No Products Found
                                </Typography>
                              </div>
                            }
                        </div>
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            }
            {renderAllProducts && renderMoreProducts()}
          </Container>
          </Paper>
        </div>
      }
    </div>
  )

}

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
    
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandDetailScreen);