import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import ReferenceCatalogLayout from 'components/src/modules/reference-catalog/layout/ReferenceCatalogLayout/ReferenceCatalogLayout';
import { NotFound404 } from 'components/src/modules/reference-catalog/components/NotFound404/NotFound404';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Notifier } from 'components/src/modules/shared/components/';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#ffb300',
		},
	},
});

const isUserAuthenticated = () => {
	return window.localStorage.getItem('headers') && window.localStorage.getItem('persist:root');
};

const AppNavigator = (props: any) => {


	return (
		<MuiThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3}>
				<Notifier />
				<Router>
					<Switch>
						<Route path="/reference-catalog/:token" render={() => <ReferenceCatalogLayout	 />} />
						<Route path="*" component={NotFound404} />
					</Switch>
				</Router>
			</SnackbarProvider>
		</MuiThemeProvider>
	);
};


const mapStateToProps = (state: any) => {
	return {
		userLocalData: state.userLocalData
	};
};

export default AppNavigator;
