import { Home, ProductDetailScreen } from '../screens';

export const ReferenceCatalogPaths = {
  home: '/reference-catalog/:token',
  productDetail: '/reference-catalog/:token/product/:id',
  brandDetail: '/reference-catalog/:token/brands/detail/:id',
  categoryDetail: '/reference-catalog/:token/categories/detail/:id',
  brandCompanyDetail:'/reference-catalog/:token/brands/companies/detail/:id',
  parentCategoryDetail: '/reference-catalog/:token/p-categories/detail/:id'
};


export const ReferenceCatalogRoutes: any = [
  {
    path: ReferenceCatalogPaths.home,
    component: Home,
    requiredPermissions: ['']
  },
  {
    path: ReferenceCatalogPaths.productDetail,
    component: ProductDetailScreen,
    requiredPermissions: [''],
  }
];
