import React, { useEffect, useState, useRef } from 'react';
import { Theme, createStyles, makeStyles, fade, withStyles, emphasize } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import GlobalStyles from '../../../../../styles/GlobalStyles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbLink: {
      display:'flex',
      "&:hover": { 
        cursor: 'pointer',
        textDecoration:'underline'
      },
    },
    breadcrumb: {
      height: theme.spacing(3),
      color: theme.palette.grey[800],
      fontWeight: theme.typography.fontWeightRegular,
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: GlobalStyles.secondaryGreyDisabled,
      },
    },
  })
);


const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor:  theme.palette.grey[300],
      cursor: 'pointer',
      textDecoration:'underline'
    },
    // '&:active': {
    //   boxShadow: theme.shadows[1],
    //   backgroundColor: GlobalStyles.secondaryGreyDisabled,
    // },
    
  },

  
}))(Chip) as typeof Chip;


export const Breadcrumb = (props) => {
  const classes = useStyles({});
  const { crumbsData, selectedCrumb } = props

  console.log("CRUMBSPROPS", crumbsData)
  
  return (
    <div style={{justifyContent:'center'}}>
      <Breadcrumbs separator="›" >
        {
        crumbsData.length > 0 ?
          crumbsData.map((crumb) => {
            const disabled = crumb.selected ? true : false;
            
            return (
              <div
                key={ crumb.id }
                className={ disabled ? classes.breadcrumb : null}
                // style={{alignItems:'center'}}
              >
                <StyledBreadcrumb disabled={disabled} label={crumb.label }  onClick={ disabled ? null : () => selectedCrumb(crumb) } />
                
              </div>
            );
          }) : ''
        }
        </Breadcrumbs>
    </div>
  );
}

