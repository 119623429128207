import React, { useEffect, useState, useRef } from 'react';
import { Route, Switch, useParams,Redirect, useRouteMatch } from "react-router-dom";

import { Theme, createStyles, makeStyles, fade, emphasize, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { logOutReferenceCatalogUser } from '../../../data/actions';
import { getProductsByPage } from '../../../../products/api/Api';
import { handleError, checkStatus, parseJSON } from '../../../../shared/api/core';
import composeRequest from '../../../../shared/api/core';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../../shared/lib/Localization';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from '../BreadCrumbs/BreadCrumbs';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      overflow: 'hidden'
    },
    nav:{
      display: 'flex',
      flexDirectiom:'row',
      placeContent:'center space-between',
      alignItems:'center',
      width:'100%'
    },
    logoBreadCrumb:{
      flexDirection: 'row',
      display: 'flex',
      placeContent: 'center flex-start',
      alignItems: 'center',
    },
    breadCrumbs: {
      placeContent: 'center flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      display: 'flex',
      
    },

    toolbar: theme.mixins.toolbar,
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        // width: 'auto',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      // [theme.breakpoints.up('md')]: {
      //     width: '70%',
      // },
    },
    box: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '30%',
      alignSelf: 'flex-end'
    },
    logoButton: {
      width: 200,
      marginRight: theme.spacing(1),
      fontSize: 20,
      '&:hover': {
        cursor: 'pointer'
      }
    },

  }),
);

interface Props {
  // onSearch: (event) => void;
  // clearSearch: () => void;
  // onSearchInputBlur?: () => void;
  // onSearchInputFocus?: () => void;
  // searchTerm: string;
  placeholder?: string;
  signOutUser: () => void;
  crumbsData: any ;
  selectedCrumb: any;
  authData: any;
  logoClick:(event) => void
}


const NavBar = (props: Props) => {
  // console.log(props);
  const classes = useStyles({});
  const history = useHistory();
  const navigate = (path) => { history.push(path); };
  const { placeholder, authData ,crumbsData, selectedCrumb, logoClick } = props;

  console.log("props-Nav",props)
  

  const _signOutAsync = async () => {
    try {
      await localStorage.removeItem('headers');
      props.signOutUser();
    } catch {
      // console.log('error in localStorage');
    }
    setTimeout(() => (window.location.href = '/'), 200);
  };

  const getSupplierName = () => {
    if (authData) {
      if (authData.authData) {
        return (
          <a
            className={classes.logoButton}
            color="inherit"
            aria-label="logo"
            onClick={logoClick}
          > {`${authData.authData.business.name}`}
          </a>
        )
      }
      else {
        return (
          <a
            className={classes.logoButton}
            color="inherit"
            aria-label="logo"
            onClick={logoClick}
          > Supplier Logo
          </a>
        )
      }
    }
  }


  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        {/* <Container > */}
          <Toolbar style={{ minHeight: 70 }}>
          <div className={classes.nav}>

            <div className={classes.logoBreadCrumb}>
              <div className={classes.logoBreadCrumb}>
                { getSupplierName() }
                <div className={classes.breadCrumbs}>
                  {
                    crumbsData.length > 0 &&
                    <Breadcrumb
                      crumbsData={crumbsData}
                      selectedCrumb={selectedCrumb}
                    />
                  }
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
              {/* <div className={classes.box}>
                    <SearchBar
                        onSearch={onSearch}
                        onBlur={onSearchInputBlur}
                        onFocus={onSearchInputFocus}
                        searchTerm={searchTerm}
                        clearSearch={clearSearch}
                        placeholder={'SearchProducts...'} 
                    />
                  </div> */}
            </div>
          </div>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signOutUser: () => dispatch(logOutReferenceCatalogUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
