import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';

import { PaginatedResponse } from '../../../shared/models';
import { getMasterProductDetail, } from '../../api/Api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import {
  FormControl, Dialog, IconButton, Toolbar, AppBar, Input, ListSubheader,
  LinearProgress, Button, Container, Paper, List, ListItemText, ListItem, CardMedia,
  Link, Card, CardContent, Divider, CircularProgress, Grid, Typography
} from '@material-ui/core/';
import _filter from 'lodash/filter';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ToastType } from '../../../shared/models';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
  ERROR_404
} from '../../../shared/lib/Localization';
import { Image } from '../../components'

// import { GlassMagnifier, SideBySideMagnifier } from 'react-image-magnifiers'

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      margin: '6px!important',
      width: '100%'
    },
    mainCard: {
      width: '100%',
      marginTop: '5px',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignContent: 'flex-start',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    imageContent: {
      flexDirection: 'column',
      display: 'flex',
      // placeContent:'flex-start space-around',
      alignItems: 'center'
    },
    productImage: {
      height: '150px',
      width: '100px'
    },
    productCardContent: {
      flexDirection: 'column',
      display: 'flex',
      placeContent: 'stretch center',
      alignItems: 'stretch',
      width: '67%',
      margin: '0 0 16px'
    },
    metaData: {
      flexDirection: 'row',
      display: 'flex',
      placeContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    grid: {
      flexGrow: 1,
      padding: '10px',
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '10px',
      marginTop: '10px',
    },


    heading: {
      color: GlobalStyles.primaryColor,
      fontWeight: 'bold',
      fontSize: '15px',
      "&:hover": {
        cursor: 'pointer',
      },
    },
    panelCol: {


    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px'
    },
    panelImage: {
      height: '150px',
      width: '200px',
      marginLeft: '10px',
      alignSelf: 'center'
    },
    gridList: {
      height: '100%',
      marginTop: '30px'
    },

    panelCardroot: {
      display: 'flex',
      width: '300px',
      height: '170px',
      // border: `4px solid ${theme.palette.grey['300']}`,
      transition: "transform 0.15s ease-in-out",
      "&:hover": {
        boxShadow: `0 6px 6px -3px rgb(0 0 0 / 20%), 
          0 10px 14px 1px rgb(0 0 0 / 14%),
          0 4px 18px 3px rgb(0 0 0 / 12%)`,
        // cursor: 'pointer',
      },
    },
    panelCardImage: {
      height: '150px',
      width: '200px',
      marginLeft: '10px',
      alignSelf: 'center',
      "&:hover": {
        cursor: 'pointer',
      }
    },

    panelCardDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      width: '197px'
    },

    panelCardContent: {
      flex: '1 0 auto',
      opacity: '0.8',
      textAlign: 'center'
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    divider: {
      margin: '30px auto',
      height: '1px',
      width: '100%',
      backgroundColor: '#d3d3d3',
    },

    brandLink: {
      placeContent: 'stretch center',
      color: GlobalStyles.secondary,
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      marginBottom: '30px',
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },
    companyLink: {
      placeContent: 'stretch center',
      color: GlobalStyles.secondary,
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      marginBottom: '30px',
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },
    panelBrandLink: {
      fontSize: '0.75rem',
      marginTop: '5px',
      marginBottom: '5px',
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },
    panelCommodityGroupLink: {
      fontSize: '0.65rem',
      opacity: '0.5',
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },
    moreLink: {
      fontSize: '0.75rem',
      padding: '10px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },

    appBar: {
      position: 'relative',
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },

    progressBar: {
      padding: 1
    },

  })
)

interface Props {
  searchTerm: string | undefined;
  userData: any;
  history: any;
  clearSearch: () => void;
  location: any;
  match: any;
}



const ProductDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [productData, setProductData] = useState({ ...props.location.state });
  const [expanded, setExpanded] = React.useState(false);
  const [similarProducts, setSimilarProducts] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [showCrumbs, setShowCrumbs] = useState(false)
  const [allProducts, setAllProducts] = useState([])
  const [renderAllProducts, setRenderAllProducts] = useState(false)
  const [moreButtonClicked, setMoreButtonClicked] = useState(false);

  const [mediaUrls, setMediaUrls] = useState([])
  const [isMediaLoading, setMediaLoading] = useState(false)
  const [mediaData, setMediaData] = useState([])
  const [userSelectedMedia, setUserSelectedMedia] = useState(null)
  const [showDefaultMedia, setShowDefaultMedia] = useState(false);
  const [defaultMediaData, setDefaultMediaData] = useState(null as any)
  const [inActiveMedia, setInActiveMedia] = useState([])
  const [noRelatedProductsFound, setNoRelatedProductsFound] = useState(false)

  const authId = props.userData.authData.auth_token


  useEffect(() => {
    console.log("props", props)
  }, [props])

  useEffect(() => {

    if (props.location && props.location.state) {
      if (props.location.state.master_product.id) {
        // console.log(props.location.state)
        setLoading(true);
        getMasterProductDetail(props.location.state.master_product.id, props.location.state.business)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(data => {
            if (data.master_product && data.msg) {
              setNoRelatedProductsFound(true)
            }
            else {
              // props.setPageLoader(false);
              let filteredData = []
              setSimilarProducts(data.similar_products.slice(0, 9))
              setAllProducts(data.similar_products)
            }
            setLoading(false)
            setProductData(data.master_product[0])
            if (data.master_product[0].master_product.image_data) {
              setMediaData(data.master_product[0].master_product.image_data)
              setShowDefaultMedia(false)
            }
            else {
              setShowDefaultMedia(true)
            }

            setShowCrumbs(true)
          })
          .catch((error: any) => {
            // props.setPageLoader(false);
            let msg = 'Failed to fetch Product Details'
            if (error && error.status) {
              if (error.status === 403 || error.status === 401) {
                msg = NO_PERMISSIONS_ERROR_MSG;
              } else if (error.status === 500) {
                msg = ERROR_500;
              }
              else if (error.status === 404) {
                msg = ERROR_404
              }
            }
            setLoading(false)
            props.showToast({
              message: msg,
              options: ToastError
            });
          });
        setShowCrumbs(false)
      }
    }

  }, [props.location.state])


  useEffect(() => {
    if (showCrumbs && !moreButtonClicked) {
      productData.master_product.brand.mp_brand_company ?
        props.crumbsData([
          {
            'id': 1,
            'label': `${productData.master_product.brand.mp_brand_company.name}`,
            'type': 'company'
          },
          {
            'id': 2,
            'label': `${productData.master_product.brand.name}`,
            'type': 'brand'
          },
          {
            'id': 3,
            'label': `${productData.business_product.commodityGroup.name}`,
            'type': 'category'
          },
          {
            'id': 4,
            'label': `${productData.master_product.name}`,
            'type': 'product',
            'selected': true
          },
        ])
        :
        props.crumbsData([
          {
            'id': 1,
            'label': `${productData.master_product.brand.name}`,
            'type': 'brand'
          },
          {
            'id': 2,
            'label': `${productData.business_product.commodityGroup.name}`,
            'type': 'category'
          },
          {
            'id': 3,
            'label': `${productData.master_product.name}`,
            'type': 'product',
            'selected': true
          },
        ])
      // console.log("SELECTED-CRUMB", props.selectedCrumb)
    }

    if (moreButtonClicked && !showCrumbs) {
      props.crumbsData([
        {
          'id': 1,
          'label': `${productData.master_product.brand.mp_brand_company.name}`,
          'type': 'company'
        },
        {
          'id': 2,
          'label': `All Products`,
          'type': 'all-products',
          'selected': true
        },

      ])
    }

  }, [showCrumbs, moreButtonClicked])


  useEffect(() => {
    if (props.selectedCrumb) {
      if (props.selectedCrumb.type === 'brand') {
        navigateToBrandDetailScreen(productData.master_product.brand)
      }
      if (props.selectedCrumb.type === 'category') {
        navigateToCategoryDetailScreen(productData.business_product.commodityGroup)
      }
      if (props.selectedCrumb.type === 'company') {
        navigateToCompanyDetailScreen(productData.master_product.brand.mp_brand_company)
      }
    }
  }, [props.selectedCrumb])

  useEffect(() => {
    let defaultImage;
    let inactiveImageUrls;
    if (mediaData.length > 0) {

      let urls = mediaData.map(i => i.media_core ? i.media_core.source : '');
      setMediaUrls(urls)
      // console.log("IMAGE-URLS", urls)

      defaultImage = mediaData.filter(i => i.default === true)
      setDefaultMediaData(defaultImage)
      setUserSelectedMedia(mediaData ? mediaData[0].media_core.source : null)

      // console.log("Default Image", defaultImage)

      inactiveImageUrls = mediaData.filter(i => i.is_inactive === true)
      setInActiveMedia(inactiveImageUrls)
      // console.log("INACTIVE-IMAGES", inactiveImageUrls)

    }
  }, [mediaData])



  const getDefaultImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };


  const handlePanelExpansion = () => {
    setExpanded(!expanded);
  };


  const navigateToProductDetailScreen = (data: any) => {
    const productDetailUrl = '/reference-catalog/' + `${authId}` + '/product/' + `${data.master_product.id}`;
    // console.log(productDetailUrl)
    renderAllProducts ? handlePanelExpansion() : null
    renderAllProducts ? setRenderAllProducts(false) : null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false) : null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(productDetailUrl, data);
  }

  const navigateToBrandDetailScreen = (data: any) => {
    const brandDetailUrl = '/reference-catalog/' + `${authId}` + '/brands/detail/' + `${data.id}`;
    // console.log(brandDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) : null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false) : null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(brandDetailUrl, data);
  }

  const navigateToCategoryDetailScreen = (data: any) => {
    const categoryDetailUrl = '/reference-catalog/' + `${authId}` + '/categories/detail/' + `${data.uuid}`;
    // console.log(categoryDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) : null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false) : null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(categoryDetailUrl, data);
  }

  const navigateToCompanyDetailScreen = (data: any) => {
    const companyDetailUrl = '/reference-catalog/' + `${authId}` + '/brands/companies/detail/' + `${data.id}`;
    // console.log(productDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) : null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false) : null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(companyDetailUrl, data);
  }


  const handlePanelProductImageOnClick = (data: any) => {
    if (data) {
      navigateToProductDetailScreen(data)
    }
  }

  const handlePanelBrandOnClick = (data: any) => {
    if (data) {
      navigateToBrandDetailScreen(data)
    }
  }

  const handlePanelCategoryOnClick = (data: any) => {
    if (data) {
      navigateToCategoryDetailScreen(data)
    }
  }

  const handlePanelCompanyOnClick = (data: any) => {
    if (data) {
      navigateToCompanyDetailScreen(data)
    }
  }


  const handleClickOpen = (e) => {
    // e.preventDefault()
    setRenderAllProducts(true)
    setMoreButtonClicked(true)
    setShowCrumbs(false)
  };

  const handleSelectedMedia = (data: any) => {
    setUserSelectedMedia(data)
  }


  const getImagePath = (productData: any) => {
    if (productData) {
      if (productData.image_data && productData.image_data.length > 0) {
        let defaultImg = productData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      else if (productData.brand && productData.brand.media_anchor && productData.brand.image_data && productData.brand.image_data.length > 0) {
        let defaultImg = productData.brand.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      else if (productData.brand && productData.brand.company && productData.brand.mp_brand_company.media_anchor && productData.brand.mp_brand_company.image_data && productData.brand.mp_brand_company.image_data.length > 0) {
        let defaultImg = productData.brand.mp_brand_company.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getDefaultImagePath()
    }
  }

  const getSimilarProducts = () => {
    return similarProducts.map((p, index) => {
      return (
        <Grid key={index} item>
          <Card
            className={classes.panelCardroot}
            key={index}
          >
            <CardMedia
              className={classes.panelCardImage}
              image={getImagePath(p.master_product)}
              onClick={() => handlePanelProductImageOnClick(p)}
              style={{ backgroundSize: 'contain' }}
            />
            <div className={classes.panelCardDetails}>
              <CardContent className={classes.panelCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                  {p.master_product.name ? p.master_product.name : ''}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.panelBrandLink}
                  onClick={() => handlePanelBrandOnClick(p.master_product.brand)}
                >
                  {p.master_product.brand ? p.master_product.brand.name : ''}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.panelCommodityGroupLink}
                  onClick={() => handlePanelCategoryOnClick(p.business_product.commodityGroup)}
                >
                  {p.business_product.commodityGroup ? p.business_product.commodityGroup.name ? p.business_product.commodityGroup.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      )
    })
  }


  const getAllProducts = () => {
    return allProducts.map((p, index) => {
      return (
        <Grid key={index} item>
          <Card
            className={classes.panelCardroot}
            key={index}
          >
            <CardMedia
              className={classes.panelCardImage}
              image={getImagePath(p.master_product)}
              onClick={() => handlePanelProductImageOnClick(p)}
              style={{ backgroundSize: 'contain' }}
            />
            <div className={classes.panelCardDetails}>
              <CardContent className={classes.panelCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                  {p.master_product.name ? p.master_product.name : ''}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.panelBrandLink}
                  onClick={() => handlePanelBrandOnClick(p.master_product.brand)}
                >
                  {p.master_product.brand ? p.master_product.brand.name : ''}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.panelCommodityGroupLink}
                  onClick={() => handlePanelCategoryOnClick(p.business_product.commodityGroup)}
                >
                  {p.business_product.commodityGroup ? p.business_product.commodityGroup.name ? p.business_product.commodityGroup.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      )
    })
  }

  const getMetaData = () => {
    return (
      <div className={classes.metaData} >
        <div style={{ marginRight: '150px', display: 'block' }}>
          <Typography variant='h3' style={{ marginBottom: '30px', textAlign: 'center', color: GlobalStyles.primaryColor }}>
            {productData.master_product.name ? productData.master_product.name : ''}
          </Typography>
          <Typography variant='h4'
            className={classes.brandLink}
            onClick={() => navigateToBrandDetailScreen(productData.master_product.brand)}
          >
            {productData.master_product.brand ? productData.master_product.brand.name ? productData.master_product.brand.name : '' : ''}
          </Typography>
          <Typography
            className={classes.brandLink}
            variant='h5'
            onClick={() => handlePanelCompanyOnClick(productData.master_product.brand.mp_brand_company)}
          >
            {productData.master_product.brand ? productData.master_product.brand.mp_brand_company ? productData.master_product.brand.mp_brand_company.name ? productData.master_product.brand.mp_brand_company.name : '' : '' : ''}
          </Typography>
        </div>
        <div>
          {productData.image_data && productData.imageData.length > 0 ?

            <Image
              itemData={productData ? productData : null}
              mediaUrls={mediaUrls ? mediaUrls.length > 0 ? mediaUrls : null : null}
              mediaData={mediaData ? mediaData.length > 0 ? mediaData : null : null}
              showDefaultMedia={showDefaultMedia ? showDefaultMedia : null}
              defaultMediaData={defaultMediaData ? defaultMediaData : null}
              handleSelectedMedia={handleSelectedMedia}
              userSelectedMedia={userSelectedMedia ? userSelectedMedia : null}
            /> :
            <img
              alt={productData.master_product.name ? productData.master_product.name : ''}
              src={getImagePath(productData.master_product)}
              style={{ objectFit: 'contain', height: '225px', width: '225px' }}
            />
          }

        </div>
      </div>
    )
  }




  const renderMoreProducts = () => {
    return (
      <div>
        <Paper elevation={0}>
          <Grid container className={classes.grid} item xs={12} spacing={2}>
            {getAllProducts()}
          </Grid>
        </Paper>
      </div>
    )
  }




  return (
    <div>
      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading &&
        <div>
          <Paper elevation={3} square={false} className={classes.content} >
            <Container style={{ paddingBottom: '18px', paddingTop: '10px' }} fixed >
              {
                !renderAllProducts &&
                <div className={classes.mainCard}>
                  <div style={{ width: '100%' }}>
                    <div style={{ display: 'block' }}>
                      <div >
                        {getMetaData()}
                      </div>
                      {/* <div>       
                    {Description}
                  </div> */}
                      <Divider className={classes.divider} />
                      <div >
                        <ExpansionPanel
                          expanded={expanded}
                          onChange={handlePanelExpansion}
                          style={{ border: '1px  rgba(0, 0, 0, .125)' }}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                          // style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                          >
                            <Typography className={classes.heading}>Related products</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                            style={{ borderBottom: '1px rgba(0, 0, 0, .125)' }}
                          >
                            {noRelatedProductsFound ? (
                              <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding:10 }} >
                                <Typography style={{ color: GlobalStyles.warningText, fontSize: '0.85rem' }} variant='h6'> No Related products found!</Typography>
                              </div>
                            ) : (
                              <div>
                                <Grid container className={classes.grid} item xs={12} spacing={2}>
                                  {getSimilarProducts()}
                                </Grid>
                                {allProducts && allProducts.length > 9 &&
                                  <div
                                    className={classes.moreLink}
                                    onClick={handleClickOpen}
                                  >
                                    <Typography style={{ color: GlobalStyles.primaryColor }}>
                                      {`More >>`}
                                    </Typography>
                                  </div>
                                }
                              </div>
                            )
                            }
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {renderAllProducts && renderMoreProducts()}
            </Container>
          </Paper>
        </div>
      }
    </div>
  )

}

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,

  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailScreen);