export const sortOptions = (option: any) => {
  switch (option) {
    case 'all': return [
      {
        id: 1,
        name: 'Featured',
        value: 'featured',
      },
      {
        id: 2,
        name: 'Brand (A-Z)',
        value: 'master_product__brand__name',
      },
      {
        id: 3,
        name: 'Brand (Z-A)',
        value: '-master_product__brand__name',

      },
      {
        id: 4,
        name: 'Category (A-Z)',
        value: 'business_product__commodityGroup__name',
      },
      {
        id: 5,
        name: 'Category (Z-A)',
        value: '-business_product__commodityGroup__name',

      },
      {
        id: 6,
        name: 'Company (A-Z)',
        value: 'master_product__brand__company__name',
      },
      {
        id: 7,
        name: 'Company (Z-A)',
        value: '-master_product__brand__company__name',
      },

    ]
    case 'brands': return [
      {
        id: 1,
        name: 'Featured',
        value: 'featured',
      },
      {
        id: 2,
        name: 'Company (A-Z)',
        value: 'company__name',
      },
      {
        id: 3,
        name: 'Company (Z-A)',
        value: '-company__name',
      },
    ]
    case 'categories': return [
      {
        id: 1,
        name: 'Featured',
        value: 'featured',
      },
      {
        id: 2,
        name: 'Parent Category (A-Z)',
        value: 'parentCommodityGroup__name',
      },
      {
        id: 3,
        name: 'Parent Category (Z-A)',
        value: '-parentCommodityGroup__name',
      },
    ]
    case 'companies': return [
      {
        id: 1,
        name: 'Featured',
        value: 'featured',
      }
    ]
  }
}



