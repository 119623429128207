import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';

import { getParentCategoryDetail } from '../../api/Api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import _isEqual from 'lodash/isEqual';
import {
  FormControl, Dialog, IconButton, Toolbar, AppBar, Input, ListSubheader,
  LinearProgress, Button, Container, Paper, List, ListItemText, ListItem,CardMedia, Divider,
  Card, CardContent, CircularProgress, Typography, Grid,  Slide, Link
  
} from '@material-ui/core/';
import _filter from 'lodash/filter';
import { ToastType } from '../../../shared/models';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
  ERROR_404
} from '../../../shared/lib/Localization';

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content:{
      margin:'6px!important',
      width:'100%',
      
    },
    mainCard:{
      minHeight:'89vh',
      placeContent:'stretch center',
      alignItems:'stretch',
      flexDirection:'row'
    },
    parentCategoryDataContent:{
      width:'100%',

    },
    metaData:{
      flexDirection:'row',
      display:'flex',
      placeContent:'center',
      alignItems:'center',
      justifyContent:'space-between'
    },
    divider: {
      margin:'30px auto',
      height:'1px',
      width:'100%',
      backgroundColor:'#d3d3d3',
    },
    grid: {
      flexGrow: 1,
      padding:'10px',
      flexFlow:'row wrap',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',

    },
    panelCardroot:{
      display: 'flex',
      width:'300px',
      height:'170px',
      // border: `4px solid ${theme.palette.grey['300']}`,
      transition: "transform 0.15s ease-in-out",
      "&:hover": { 
        boxShadow:`0 6px 6px -3px rgb(0 0 0 / 20%), 
          0 10px 14px 1px rgb(0 0 0 / 14%),
          0 4px 18px 3px rgb(0 0 0 / 12%)`,
      },
    },
    panelCardImage:{
      height:'150px',
      width: '200px',
      marginLeft:'10px',
      alignSelf:'center',
      "&:hover": { 
        cursor: 'pointer',
      }
    },
    panelCardDetails:{
      display: 'flex',
      flexDirection: 'column',
      alignSelf:'center',
      width:'197px'
    },
    panelCardContent:{
      flex: '1 0 auto',
      opacity: '0.8',
      textAlign:'center'
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    panelBrandLink:{
      fontSize: '0.75rem', 
      marginTop:'5px', 
      marginBottom:'5px',
      "&:hover": { 
        cursor: 'pointer',
        textDecoration:'underline'
      },
    },
    appBar: {
      position: 'relative',
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    moreLink:{
      fontSize: '0.75rem', 
      padding:'10px',
      display:'flex',
      justifyContent:'flex-end',
      marginRight:'10px',
      "&:hover": { 
        cursor: 'pointer',
        textDecoration:'underline'
      },
    },

    metaDataCompanyLink:{
      placeContent: 'stretch center', 
      color:GlobalStyles.secondary, 
      display: 'flex', 
      alignItems: 'stretch', 
      flexDirection: 'row',
      marginBottom: '30px',
      "&:hover": { 
        cursor: 'pointer',
        textDecoration:'underline'
      },
    },

    panelCommodityGroupLink:{
      fontSize: '0.65rem', 
      opacity: '0.5',
      "&:hover": { 
        cursor: 'pointer',
        textDecoration:'underline'
      },
    },


  })
)

interface Props {
  searchTerm: string | undefined;
  userData: any;
  history: any;
  clearSearch: () => void;
  location: any;
  match: any;
}



const ParentCategoryDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [parentCategoryData, setParentCategoryData] = useState({ ...props.location.state });
  const [expanded, setExpanded] = React.useState(false);
  const [parentCategoryProducts, setParentCategoryProducts]=useState([])
  const[isLoading, setLoading]=useState(false)
  const [allProducts, setAllProducts]=useState([])
  const [renderAllProducts, setRenderAllProducts]= useState(false)
  const [showCrumbs, setShowCrumbs]= useState(false)
  const [moreButtonClicked, setMoreButtonClicked]= useState(false)
  const [noProductsFound,setNoProductsFound] = useState(false)

  const authId = props.userData.authData.auth_token
  const businessId = props.userData.authData.business.id

  useEffect(() => {
    console.log("props", props)
   },[props])

  useEffect(() => {
    if (props.location && props.location.state) {
      if (props.location.state.uuid) {
        setLoading(true);
        // props.selectedCrumb(null)
        getParentCategoryDetail(props.location.state.uuid, businessId)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(data => {
            // props.setPageLoader(false);
            setLoading(false)
            setParentCategoryData(data.par_category_details)
            setParentCategoryProducts(data.par_category_products.slice(0,9))
            setAllProducts(data.par_category_products)
            setShowCrumbs(true)
          })
          .catch((error: any) => {
            // props.setPageLoader(false);
            let msg = 'Failed to fetch Category Details.';
            if (error && error.status) {
              if (error.status === 403 || error.status === 401) {
                msg = NO_PERMISSIONS_ERROR_MSG;
              } else if (error.status === 500) {
                msg = ERROR_500;
              }
              else if(error.status === 404){
                msg = ERROR_404
                setNoProductsFound(true)
              }

            }
            setLoading(false)
            props.showToast({
              message: msg,
              options: ToastError
            });
            setShowCrumbs(false)
          });
      }
    }

  },[props.location.state])


  useEffect(() => {
    if (showCrumbs && !moreButtonClicked) {
        props.crumbsData([
          {
            'id': 1,
            'label': `${parentCategoryData.name}`,
            'type': 'parent-category',
            'selected': true
          },
        ])
      // console.log("SELECTED-CRUMB", props.selectedCrumb)
    }
    if(!showCrumbs && moreButtonClicked){
      props.crumbsData([
        {
          'id': 1,
          'label': `${parentCategoryData.name}`,
          'type': 'parent-category'
        },
        {
          'id': 2,
          'label': `All Products`,
          'type': 'all-products',
          'selected': true
        },
      ]) 

    }
  }, [showCrumbs, moreButtonClicked])



  useEffect(()=>{
    if(props.selectedCrumb){
      if(props.selectedCrumb.type === 'parent-category'){
        handleParentCategoryOnClick(parentCategoryData)
      }
     
    }
  },[props.selectedCrumb])

  const getImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };



  const navigateToProductDetailScreen = (data: any)=>{
    const productDetailUrl = '/reference-catalog/' + `${authId}` + '/product/'  + `${data.master_product.id}`;
    // console.log(productDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) :  null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false): null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(productDetailUrl, data);
  }

  const navigateToBrandDetailScreen = (data: any)=>{
    const brandDetailUrl = '/reference-catalog/' + `${authId}` + '/brands/detail/'  + `${data.id}`;
    // console.log(brandDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) :  null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false): null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(brandDetailUrl, data);
  }

  const navigateToCategoryDetailScreen = (data: any)=>{
    const categoryDetailUrl = '/reference-catalog/' + `${authId}` + '/categories/detail/'  + `${data.uuid}`;
    // console.log(categoryDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) :  null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false): null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(categoryDetailUrl, data);
  }

  const navigateToParentCategoryDetailScreen = (data: any)=>{
    const parentCategoryDetailUrl = '/reference-catalog/' + `${authId}` + '/p-categories/detail/'  + `${data.uuid}`;
    // console.log(categoryDetailUrl)
    renderAllProducts ? setRenderAllProducts(false) :  null
    moreButtonClicked ? setMoreButtonClicked(false) : null
    showCrumbs ? setShowCrumbs(false): null
    props.crumbsData.length > 0 ? props.crumbsData([]) : null
    props.selectedCrumb ? Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null) : null
    props.history.push(parentCategoryDetailUrl, data);
  }

  const handlePanelProductImageOnClick = (data: any) => {
    if (data) {
      navigateToProductDetailScreen(data)
    }
  }

  const handlePanelBrandOnClick = (data: any) => {
    if (data) {
      navigateToBrandDetailScreen(data)
    }

  }

  const handlePanelCategoryOnClick = (data: any) => {
    if (data) {
      navigateToCategoryDetailScreen(data)
    }
  }

  const handleParentCategoryOnClick = (data: any) => {
    if (data) {
      navigateToParentCategoryDetailScreen(data)
    }
  }



  const handleClickOpen = () => {
    setRenderAllProducts(true)
    setMoreButtonClicked(true)
    setShowCrumbs(false)
  };


  const getParentCategoryProducts = () => {
    return parentCategoryProducts.map((p, index) => {
      return (
        <Grid key={index} item>
          <Card 
            className={classes.panelCardroot} 
            key={index}
            
          >
            <CardMedia
              className={classes.panelCardImage}
              image={getImagePath()}
              onClick={()=>handlePanelProductImageOnClick(p)}
            />
            <div className={classes.panelCardDetails}>
              <CardContent className={classes.panelCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                  {p.master_product.name ? p.master_product.name : ''}
                </Typography>
                <Typography 
                  variant='body2'
                  className={classes.panelBrandLink}
                  onClick={()=>handlePanelBrandOnClick(p.master_product.brand)}
                >
                  {p.master_product.brand ? p.master_product.brand.name : ''}
                </Typography>
                <Typography
                  className={classes.panelCommodityGroupLink}  
                  variant='body1'
                  onClick={()=>handlePanelCategoryOnClick(p.business_product.commodityGroup)}
                >
                  {p.business_product.commodityGroup ? p.business_product.commodityGroup.name ? p.business_product.commodityGroup.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      )
    })
  }


  const getMetaData =()=>{
    return(
      <div className={classes.metaData} >
        <div style={{ marginRight: '150px', display: 'block' }}>
          <Typography variant='h2' style={{ marginBottom: '30px', textAlign: 'center',color:GlobalStyles.primaryColor }}>
            {parentCategoryData.name ? parentCategoryData.name : ''}
          </Typography>
        </div>
        <div>
          <img
            alt={parentCategoryData.name ? parentCategoryData.name : ''}
            src={getImagePath()}
            style={{ height: '250px', width: '250px' }}
          />
        </div>
      </div>
    )
  }


  const getParentCategoryStory=()=>{
    return(
      <div>
        <div style={{placeContent:'center', display:'flex',alignItems:'center',flexDirection:'row',}}>
          <Typography variant='h5' style={{marginBottom:'16px'}}>
            Our Story
          </Typography>
        </div>
        <div style={{marginBottom:'16px', display:'block'}}>
          <Typography style={{ fontSize: '14px' }}>

          </Typography>
        </div>
      </div>
    )
  }


  const getAllProducts = () => {
    return allProducts.map((p, index) => {
      return (
        <Grid key={index} item>
          <Card 
            className={classes.panelCardroot} 
            key={index}
            
          >
            <CardMedia
              className={classes.panelCardImage}
              image={getImagePath()}
              onClick={()=>handlePanelProductImageOnClick(p)}
            />
            <div className={classes.panelCardDetails}>
              <CardContent className={classes.panelCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                  {p.master_product.name ? p.master_product.name : ''}
                </Typography>
                <Typography 
                  variant='body2'
                  className={classes.panelBrandLink}
                  onClick={()=>handlePanelBrandOnClick(p.master_product.brand)}
                >
                  {p.master_product.brand ? p.master_product.brand.name : ''}
                </Typography>
                <Typography
                  className={classes.panelCommodityGroupLink} 
                  variant='body1'
                  onClick={()=>handlePanelCategoryOnClick(p.business_product.commodityGroup)} 
                >
                  {p.business_product.commodityGroup ? p.business_product.commodityGroup.name ? p.business_product.commodityGroup.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      )
    })
  }

  

  const renderMoreProducts = () => {
    return (
      <div>
          <Paper elevation={0}>
            <Grid container className={classes.grid} item xs={12} spacing={2}>
              {getAllProducts()}
            </Grid>
          </Paper>
      </div>
    )
  }




  return (
    <div >
      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading &&
        <div>
          <Paper elevation={3}  square={false}  className={classes.content} >
          <Container style={{paddingBottom:'18px', paddingTop:'10px'}} fixed >
            {
              !renderAllProducts &&
              <div className={classes.mainCard}>
                <div className={classes.parentCategoryDataContent}>
                  <div style={{ display: 'block' }}>
                    <div >
                      {getMetaData()}
                    </div>
                    <Divider className={classes.divider} />
                    {/* <div>       
                    {getParentCategoryStory()}
                    </div> */}
                    <div>
                      <Paper elevation={0}>
                        <div>
                          <div style={{ display: 'flex', placeContent: 'center', flexDirection: 'row', alignItems: 'center', padding: '10px' }}>
                            <Typography variant='h5' style={{ marginBottom: '16px', color: GlobalStyles.primaryColor }}>
                              Products
                            </Typography>
                          </div>
                            { !noProductsFound &&
                              <div >
                                <Grid container className={classes.grid} item xs={12} spacing={2}>
                                  {getParentCategoryProducts()}
                                </Grid>
                                {
                                  allProducts.length > 9 &&
                                  <div
                                    className={classes.moreLink}
                                    onClick={handleClickOpen}
                                  >
                                    <Typography style={{ color: GlobalStyles.primaryColor }}>
                                      {`More >>`}
                                    </Typography>
                                  </div>
                                }
                              </div>
                            }
                            {
                              noProductsFound &&
                              <div style={{ display: 'flex', placeContent: 'center', flexDirection: 'row', alignItems: 'center', padding: '10px' }} >
                                <Typography  variant="h5" style={{color:GlobalStyles.warningText}}>
                                  No Products Found
                                </Typography>
                              </div>
                            }
                        </div>
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            }
            {renderAllProducts && renderMoreProducts()}
          </Container>
          </Paper>
        </div>
      }
    </div>
  )

}

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
    
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentCategoryDetailScreen);