import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography, Select, Menu, MenuItem, FormControl, InputLabel, Input } from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Paper, Divider } from '@material-ui/core';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { PaginatedResponse, Location } from '../../../shared/models';
import Pagination from '@material-ui/lab/Pagination';

import { sortOptions } from '../../models'
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import { getSupplierLinkedMps, getSupplierLinkedMpBrands, getSupplierLinkedMpCategories, getSupplierLinkedMpCompanies } from '../../api/Api';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import _uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { ReferenceCatalogPaths } from '../../Routes/Routes';
import { defaultGridPaginationQuery, initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
      float: 'left'
    },
    noImageRow: {
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    cardContent: {
      paddingBottom: '16px !important'
    },
    content: {
      padding: theme.spacing(2),
      backgroundColor: '#f6f7fC',
      height: '100%',
      overflow: 'auto'
    },
    grid: {
      flexGrow: 1,
      padding: '10px'
    },
    tile: {
      border: `4px solid ${theme.palette.grey['300']}`,
      cursor: 'pointer',
      // minHeight: 366,
      width: 230,
      // "&:hover": {
      // border: `4px solid ${theme.palette.grey['300']}`,
      // backgroundColor: 'rgb(7, 177, 77, 0.42)'
      // }
    },
    tileContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '50%',
    },
    tileTextContainer: {
      // backgroundColor: theme.palette.grey['100'],
      height: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    loadingIcon: {
      width: 500,
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      padding: theme.spacing(1),
      height: "100%"
    },
    menuBar: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: "space-between",
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },



    tab: {
      flexGrow: 1,
      backgroundColor: 'transparent',
    },

    root: {
      display: 'flex',
      width: '300px',
      height: '170px',
      // border: `4px solid ${theme.palette.grey['300']}`,
      transition: "transform 0.15s ease-in-out",
      "&:hover": {
        // transform: "scale3d(1.05, 1.05, 1)",
        boxShadow: `0 6px 6px -3px rgb(0 0 0 / 20%), 
          0 10px 14px 1px rgb(0 0 0 / 14%),
          0 4px 18px 3px rgb(0 0 0 / 12%)`,
        // cursor: 'pointer',
      },
    },
    // cardHovered:{
    //   transform: "scale3d(1.05, 1.05, 1)"
    // },
    details: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      width: '197px'
    },
    brandCardContent: {
      flex: '1 0 auto',
      opacity: '0.8',
      textAlign: 'center'
    },
    tabImage: {
      height: '150px',
      width: '200px',
      marginLeft: '10px',
      alignSelf: 'center',
      "&:hover": {
        cursor: 'pointer',
      }
    },
    brandCardDetails: {
      fontSize: '0.65rem',
      opacity: '0.5',
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },
    panelBrandLink: {
      fontSize: '0.75rem',
      marginTop: '5px',
      opacity: '0.5',
      marginBottom: '5px',
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },

    panelCommodityGroupLink: {
      fontSize: '0.65rem',
      opacity: '0.5',
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },
    pagination: {
      padding: '10px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.primaryColor,
      },
      '&:after': {
        borderColor: GlobalStyles.primaryColor,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
      maxHeight: '30px'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);
interface Props {
  // searchTerm: string | undefined;
  userData: any;
  history: any;
  // clearSearch: () => void;
  location: any;
  match: any
  crumbsData: any;
  selectedCrumb: any;
}
const pageSize = 30;
const pageSizeforBrands = 30;
const pageSizeforCategories = 30;
const pageSizeforCompanies = 30;


const initalPageData = {
  count: 0,
  next: null,
  previous: null,
  results: [] as any
} as PaginatedResponse<any>;

const Home = (props: Props) => {
  const classes = useStyles({});
  const history: any = useHistory();
  const location: any = useLocation();
  const [pageData, setPageData] = useState(initalPageData);

  const [isLoading, setLoading] = useState(false);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [tabValue, setTabValue] = useState('all')
  const [masterBrands, setMasterBrands] = useState(initalPageData)
  const [commodityGroups, setCommodityGroups] = useState(initalPageData)
  const [masterCompanies, setMasterCompanies] = useState(initalPageData)
  const [showCrumbs, setShowCrumbs] = useState(false)

  const [imageData, setImageData] = useState(null)

  const [pagination, setPagination] = useState({
    ...defaultGridPaginationQuery,
    pageSize: pageSize
  });
  const [paginationBrands, setPaginationBrands] = useState({
    ...defaultGridPaginationQuery,
    pageSize: pageSizeforBrands
  });
  const [paginationCategory, setPaginationCategory] = useState({
    ...defaultGridPaginationQuery,
    pageSize: pageSizeforCategories
  });
  const [paginationCompany, setPaginationCompany] = useState({
    ...defaultGridPaginationQuery,
    pageSize: pageSizeforCompanies
  });

  const [selectedType, setSelectedType] = useState('')
  const [selectedOption, setSelectedOption] = useState('featured')


  const authId = props.userData.authData.auth_token
  console.log("H")

  useEffect(() => {
    // console.log("PROPS", props)
  }, [props])


  useEffect(() => {
    if (tabValue === 'all') {
      fetchProducts()
    }

    if (tabValue === 'brands') {
      fetchBrands()
    }

    if (tabValue === 'categories') {
      fetchCommodityGroups()
    }

    if (tabValue === 'companies') {
      fetchCompanies()
    }


  }, [tabValue, pagination])


  useEffect(() => {
    if (showCrumbs) {
      if (props.crumbsData.length > 0) {
        props.crumbsData([])
      }
    }
  }, [showCrumbs])

  useEffect(() => {
    // console.log(props.selectedCrumb)
    if (props.selectedCrumb) {
      Object.keys(props.selectedCrumb).forEach((i) => props.selectedCrumb[i] = null);
    }
  }, [props.selectedCrumb])


  useEffect(() => {

    if (tabValue === 'all' ) {
      fetchProducts()
    }
    if (tabValue === 'brands' ) {
      fetchBrands()
    }
    if (tabValue === 'categories' ) {
      fetchCommodityGroups()
    }
    if (tabValue === 'companies' ) {
      fetchCompanies()
    }

  }, [selectedOption])


  const fetchProducts = () => {
    setLoading(true);
    const { authData } = props.userData;
    // console.log('155', authData);
    if (authData) {
      const { business } = authData;
      if (business) {
        const sb = business.id;
        let orderBy = '';
        if (selectedOption !== 'featured') {
          orderBy = selectedOption
        }
        // console.log(sb)
        getSupplierLinkedMps(sb, pagination.page, pageSize, orderBy)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then((data: any) => {
            // if (data.results.length === 1) {
            //   fetchProductDetail(data.results[0]);
            //   setCurrentSearchTerm(props.searchTerm)
            //   props.clearSearch();
            // }
            // console.log("DATA", data)
            setPageData(data);
            setLoading(false);

            if (data.results.length > 0) {
              // console.log("Incoming")
              let products = data.results
              let img = []
              products.map((p, index) => {
                if (p.master_product) {
                  // if(p.image_data && p.image_data.length > 0){
                  let obj = {}
                  obj['mp_id'] = p.master_product.id
                  obj['image_data'] = p.master_product.image_data
                  img.push(obj)
                  // console.log("Image-obj",obj)
                  // }
                }
              })
              setImageData(img)
            }
            // const currentSearchTerm = [...props.searchTerm];
            // getUniqueValues(data.results)
            setShowCrumbs(true)
          })
          .catch((error: any) => {
            setLoading(false);
            if (error) {
              if (error.status && error.status === 500) {
                setEmptyDataSourceMessage(ERROR_MSG);
                setErrorStatusVisible(false);
              }
            }
            setShowCrumbs(false)
          });
      }
    }
  }


  const fetchBrands = () => {
    setLoading(true);
    const { authData } = props.userData;
    // console.log('155', authData);
    if (authData) {
      const { business } = authData;
      if (business) {
        const sb = business.id;
        // console.log("SelectedOption", selectedOption)
        let orderBy = '';
        if (selectedOption !== 'featured') {
          orderBy = selectedOption
        }
        getSupplierLinkedMpBrands(sb, paginationBrands.page, pageSizeforBrands, orderBy)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then((data: PaginatedResponse<any>) => {
            setLoading(false);
            let masterBrands = data.results.map((i) => i)
            let uniqueBrands = _uniqBy(masterBrands, 'id')
            let updatedArr = []
            let updatedData = uniqueBrands.map(i=>i)
            for (let i=0; i< updatedData.length; i++ ){
              updatedData[i]['has_company'] = updatedData[i].company != null ? 1 : 2
            }
            updatedData = sortBy(updatedData, 'has_company')
            // console.log("UniqueBrands", updatedData)
            setMasterBrands({ ...data, results: updatedData })


          })
          .catch((error: any) => {

          });
      }
    }
  }

  const fetchCommodityGroups = () => {
    setLoading(true);
    if (showCrumbs) {
      const { authData } = props.userData;
      // console.log('155', authData);
      if (authData) {
        const { business } = authData;
        if (business) {
          const sb = business.id;
          let orderBy = '';
          if (selectedOption !== 'featured') {
            orderBy = selectedOption
          }
          getSupplierLinkedMpCategories(sb, paginationCategory.page, pageSizeforCategories, orderBy)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: PaginatedResponse<any>) => {
              setLoading(false);
              let commodityGroups = data.results.map((i) => i)
              let uniqueCgs = _uniqBy(commodityGroups, 'uuid')
              setCommodityGroups({ ...data, results: uniqueCgs })

            })
            .catch((error: any) => {

            });
        }
      }
    }
  }



  const fetchCompanies = () => {
    setLoading(true);
    if (showCrumbs) {
      const { authData } = props.userData;
      // console.log('155', authData);
      if (authData) {
        const { business } = authData;
        if (business) {
          const sb = business.id;
          getSupplierLinkedMpCompanies(sb, paginationCategory.page, pageSizeforCategories)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: PaginatedResponse<any>) => {
              setLoading(false);
              let masterCompanies = data.results.map((i) => i)
              let uniqueCompanies = sortBy(_uniqBy(masterCompanies, 'id'), 'name').filter((i) => i ? i : null)
              setMasterCompanies({ ...data, results: uniqueCompanies })

            })
            .catch((error: any) => {

            });
        }
      }
    }
  }


  // useEffect(() => {
  //   if (tabValue === 'categories') {

  //   }
  // }, [tabValue, paginationCategory])

  // useEffect(() => {
  //   if (tabValue === 'companies') {

  //   }
  // }, [tabValue, paginationCompany])



  // const getUniqueValues =(data: any) =>{

  //   let commodityGroups = data.map((i)=>i.business_product.commodityGroup)
  //   let masterBrands = data.map((i)=>i.master_product.brand)
  //   let masterCompanies = data.map((i)=>i.master_product.brand.company ? i.master_product.brand.mp_brand_company : null )

  //   setCommodityGroups(sortBy(_uniqBy(commodityGroups, 'uuid'),'name'))
  //   setMasterBrands(sortBy(_uniqBy(masterBrands,'id'),'name'))
  //   setMasterCompanies(sortBy(_uniqBy(masterCompanies,'id'),'name').filter((i)=> i ? i : null))


  //   console.log("COMMODITYGROUPS", _uniqBy(commodityGroups, 'uuid'))
  //   console.log("Master Brand",_uniqBy(masterBrands,'id'))
  //   console.log("Master Companies",_uniqBy(masterCompanies,'id').filter((i)=> i ? i : null))

  // }




  const navigateToProductDetailScreen = (data: any) => {
    const productDetailUrl = '/reference-catalog/' + `${authId}` + '/product/' + `${data.master_product.id}`;
    // console.log(productDetailUrl)
    props.history.push(productDetailUrl, data);
  }

  const navigateToBrandDetailScreen = (data: any) => {
    const brandDetailUrl = '/reference-catalog/' + `${authId}` + '/brands/detail/' + `${data.id}`;
    // console.log(brandDetailUrl)
    props.history.push(brandDetailUrl, data);
  }

  const navigateToCategoryDetailScreen = (data: any) => {
    const categoryDetailUrl = '/reference-catalog/' + `${authId}` + '/categories/detail/' + `${data.uuid}`;
    // console.log(categoryDetailUrl)
    props.history.push(categoryDetailUrl, data);
  }

  const navigateToCompanyDetailScreen = (data: any) => {
    const companyDetailUrl = '/reference-catalog/' + `${authId}` + '/brands/companies/detail/' + `${data.id}`;
    // console.log(companyDetailUrl)
    props.history.push(companyDetailUrl, data);
  }

  const navigateToParentCategoryDetailScreen = (data: any) => {
    const parentCategoryDetailUrl = '/reference-catalog/' + `${authId}` + '/p-categories/detail/' + `${data.uuid}`;
    // console.log(parentCategoryDetailUrl)
    props.history.push(parentCategoryDetailUrl, data);
  }



  const handlePanelProductImageOnClick = (data: any) => {
    if (data) {
      navigateToProductDetailScreen(data)
    }
  }

  const handlePanelBrandOnClick = (data: any) => {
    if (data) {
      navigateToBrandDetailScreen(data)
    }
  }

  const handlePanelCategoryOnClick = (data: any) => {
    if (data) {
      navigateToCategoryDetailScreen(data)
    }
  }

  const handlePanelCompanyOnClick = (data: any) => {
    if (data) {
      navigateToCompanyDetailScreen(data)
    }
  }

  const handlePanelParentCategoryOnClick = (data: any) => {
    if (data) {
      navigateToParentCategoryDetailScreen(data)
    }
  }


  const onBrandPageChange = (e, pageNumber: number) => {
    setPaginationBrands({ ...paginationBrands, page: pageNumber });
  }

  const onCategoryPageChange = (e, pageNumber: number) => {
    setPaginationCategory({ ...paginationCategory, page: pageNumber });
  }

  const onCompanyPageChange = (e, pageNumber: number) => {
    setPaginationCompany({ ...paginationCompany, page: pageNumber });
  }


  const onPageChange = (e, pageNumber: number) => {
    // console.log('onPageChange', e, pageNumber);
    setPagination({ ...pagination, page: pageNumber });

  };

  const getPageCount = (tabValue: any) => {
    if (tabValue === 'all') {
      const count = Math.ceil(pageData.count / pageSize);
      return count > 0 ? count : 1;
    }
    if (tabValue === 'brands') {
      const count = Math.ceil(masterBrands.count / pageSize);
      return count > 0 ? count : 1;
    }
    if (tabValue === 'categories') {
      const count = Math.ceil(commodityGroups.count / pageSize);
      return count > 0 ? count : 1;
    }
    if (tabValue === 'companies') {
      const count = Math.ceil(masterCompanies.count / pageSize);
      return count > 0 ? count : 1;
    }
  };

  const isPaginationDisabled = (tabValue: any) => {
    if (tabValue === 'all') {
      const { count } = pageData;
      if (count === 0) {
        return true;
      }
      return false;
    }

    if (tabValue === 'brands') {
      const { count } = masterBrands;
      if (count === 0) {
        return true;
      }
      return false;
    }

    if (tabValue === 'categories') {
      const { count } = commodityGroups;
      if (count === 0) {
        return true;
      }
      return false;
    }

    if (tabValue === 'companies') {
      const { count } = masterCompanies;
      if (count === 0) {
        return true;
      }
      return false;
    }
  };

  const getAllProductsImagePath = (productData: any) => {
    if (productData) {
      if (productData.image_data && productData.image_data.length > 0) {
        let defaultImg = productData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      else if(productData.brand && productData.brand.media_anchor && productData.brand.image_data && productData.brand.image_data.length > 0){
        let defaultImg = productData.brand.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      else if(productData.brand && productData.brand.company && productData.brand.mp_brand_company.media_anchor && productData.brand.mp_brand_company.image_data && productData.brand.mp_brand_company.image_data.length > 0 ){
        let defaultImg = productData.brand.mp_brand_company.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getDefaultImage()
    }
  }



  const getAllBrandsImagePath = (brandData: any) => {
    if (brandData) {
      if (brandData.image_data && brandData.image_data.length > 0) {
        let defaultImg = brandData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      else if (brandData.company && brandData.mp_brand_company.media_anchor && brandData.mp_brand_company.image_data && brandData.mp_brand_company.image_data.length > 0 ){
        let defaultImg = brandData.mp_brand_company.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getDefaultImage()
    }
  }

  const getAllCompaniesImagePath = (companyData: any) => {
    if (companyData) {
      if (companyData.image_data && companyData.image_data.length > 0) {
        let defaultImg = companyData.image_data.filter(i => i.default === true)
        return defaultImg && defaultImg.length === 1 ? defaultImg[0].media_core.source : null
      }
      return getDefaultImage()
    }
  }


  const getDefaultImage = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };




  const renderAllProducts = (products) => {
    return products.map((p, index) => {
      return (
        <Grid key={index} item>
          <Card
            className={classes.root}
            key={index}
          >
            <CardMedia
              className={classes.tabImage}
              image={getAllProductsImagePath(p.master_product)}
              onClick={() => handlePanelProductImageOnClick(p)}
              style={{ backgroundSize: 'contain' }}
            />
            <div className={classes.details}>
              <CardContent className={classes.brandCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                  {p.master_product.name ? p.master_product.name : ''}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.panelBrandLink}
                  onClick={() => handlePanelBrandOnClick(p.master_product.brand)}
                >
                  {p.master_product.brand ? p.master_product.brand.name : ''}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.panelCommodityGroupLink}
                  onClick={() => handlePanelCategoryOnClick(p.business_product.commodityGroup)}
                >
                  {p.business_product.commodityGroup ? p.business_product.commodityGroup.name ? p.business_product.commodityGroup.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      )
    })
  }

  const renderBrands = (brands: any) => {
    return brands.map((mb, index) => {
      return (
        <Grid key={index} item>
          <Card
            className={classes.root}
            key={index}
          >
            <CardMedia
              className={classes.tabImage}
              image={getAllBrandsImagePath(mb)}
              onClick={() => handlePanelBrandOnClick(mb)}
              style={{ backgroundSize: 'contain' }}
            />
            <div className={classes.details}>
              <CardContent className={classes.brandCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6' >
                  {mb.name ? mb.name : ''}
                </Typography>
                <Typography
                  style={{ fontSize: '0.75rem' }}
                  variant='body2'
                  className={classes.brandCardDetails}
                  onClick={() => handlePanelCompanyOnClick(mb.mp_brand_company)}
                >
                  {mb.mp_brand_company ? mb.mp_brand_company.name ? mb.mp_brand_company.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      );
    })
  }

  const renderCategories = (categories) => {
    return categories.map((c, index) => {
      return (
        <Grid key={index} item>
          <Card
            className={classes.root}
            key={index}
          >
            <CardMedia
              className={classes.tabImage}
              image={getDefaultImage()}
              onClick={() => handlePanelCategoryOnClick(c)}
              style={{ backgroundSize: 'contain' }}
            />
            <div className={classes.details}>
              <CardContent className={classes.brandCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                  {c.name ? c.name : ''}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.panelCommodityGroupLink}
                  onClick={() => handlePanelParentCategoryOnClick(c.parentCommodityGroup)}
                >
                  {c.parentCommodityGroup ? c.parentCommodityGroup.name ? c.parentCommodityGroup.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      );
    })
  }


  const renderCompanies = (companies) => {
    // console.log("COMPANIES",companies)
    return companies.map((mc, index) => {
      return (
        <Grid key={index} item>
          <Card
            className={classes.root}
            key={index}
          >
            <CardMedia
              className={classes.tabImage}
              image={getAllCompaniesImagePath(mc)}
              onClick={mc ? () => handlePanelCompanyOnClick(mc) : null}
              style={{ backgroundSize: 'contain' }}
            />
            <div className={classes.details}>
              <CardContent className={classes.brandCardContent}>
                <Typography style={{ fontSize: '0.85rem' }} variant='h6'>
                  {mc ? mc.name ? mc.name : '' : ''}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
      );
    })
  }





  const onTabChange = (e: any, newValue: any) => {
    setTabValue(newValue)
    setSelectedOption('featured')
  }

  const handleSortChange = (event: any) => {
    setSelectedOption(event.target.value)
  }


  return (
    <div style={{ width: '100%'}}>
      {/* { props.searchTerm.length <= 3 && !currentSearchTerm && props.searchTerm === '' && 
        <div style={{ width: '100%', textAlign: 'center', paddingTop: 16 }}>
          <Typography variant='h4' style={{ color: GlobalStyles.secondary }}>
            Scan or Search A Product
          </Typography>
        </div>} */}
      <Container>
        <div style={{
          margin: 'auto',
          paddingTop: 16,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center'
          // border: `4px solid`
        }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} >
            <FormControl className={classes.formControl} >
              <InputLabel id="sort-by-options" style={{ color: GlobalStyles.primaryColor }} >Sort by: </InputLabel>
              <Select
                labelId="sort-by-options"
                value={selectedOption ? selectedOption : ''}
                onChange={handleSortChange}
                className={classes.select}
                disabled={isLoading || tabValue === 'companies'}
              >
                {
                  sortOptions(tabValue).map((s: any) => (
                    <MenuItem value={s.value} key={String(s.id)}>
                      {s.name}
                    </MenuItem>
                  ))
                }
              </Select>

            </FormControl>
          </div>
          {/* <Paper elevation={3} style={{}}> */}
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

            <Paper className={classes.tab}>
              <Tabs
                value={tabValue}
                onChange={onTabChange}
                indicatorColor="primary"
                textColor="primary"
                // variant="fullWidth"
                centered
              >
                <Tab label={`All`} value="all" />
                <Tab label={`Brands`} value="brands" />
                <Tab label={`Categories`} value="categories" />
                <Tab label={`Companies`} value="companies" />

              </Tabs>
            </Paper>


            <Paper style={{ marginTop: '20px' }}>
              {
                isLoading &&
                <div className={classes.rowCenter}>
                  <CircularProgress />
                </div>
              }
              {tabValue === 'all' &&
                <div>
                  <Grid container className={classes.grid} spacing={2}>
                    <Grid item xs={12}  >
                      <Grid container justify="center" spacing={2} >
                        {
                          !isLoading &&
                          pageData.results &&
                          pageData.results.length > 0 &&
                          renderAllProducts(pageData.results)
                        }
                      </Grid>
                    </Grid>
                  </Grid>

                  <Pagination
                    boundaryCount={5}
                    disabled={isPaginationDisabled(tabValue)}
                    count={getPageCount(tabValue)}
                    page={pagination.page}
                    onChange={onPageChange}
                    showFirstButton showLastButton
                    style={{ padding: '10px', margin: 'auto', width: '55%' }}
                  />
                </div>
              }
              {
                tabValue === 'brands' &&
                <div>
                  <Grid container className={classes.grid} spacing={2}>
                    <Grid item xs={12}  >
                      <Grid container justify="center" spacing={2} >
                        {
                          !isLoading &&
                          masterBrands &&
                          masterBrands.results.length > 0 &&
                          renderBrands(masterBrands.results)
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Pagination
                    boundaryCount={5}
                    disabled={isPaginationDisabled(tabValue)}
                    count={getPageCount(tabValue)}
                    page={paginationBrands.page}
                    onChange={onBrandPageChange}
                    showFirstButton showLastButton
                    style={{ padding: '10px', margin: 'auto', width: '55%' }}
                  />
                </div>
              }
              {
                tabValue === 'categories' &&
                <div>
                  <Grid container className={classes.grid} spacing={2}>
                    <Grid item xs={12}  >
                      <Grid container justify="center" spacing={2} >
                        {
                          !isLoading &&
                          commodityGroups &&
                          commodityGroups.results.length > 0 &&
                          renderCategories(commodityGroups.results)
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Pagination
                    boundaryCount={5}
                    disabled={isPaginationDisabled(tabValue)}
                    count={getPageCount(tabValue)}
                    page={paginationCategory.page}
                    onChange={onCategoryPageChange}
                    showFirstButton showLastButton
                    style={{ padding: '10px', margin: 'auto', width: '55%' }}
                  />
                </div>
              }
              {
                tabValue === 'companies' &&
                <div>
                  <Grid container className={classes.grid} spacing={2}>
                    <Grid item xs={12}  >
                      <Grid container justify="center" spacing={2} >
                        {
                          !isLoading &&
                          masterCompanies &&
                          masterCompanies.results.length > 0 &&
                          renderCompanies(masterCompanies.results)
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Pagination
                    boundaryCount={5}
                    disabled={isPaginationDisabled(tabValue)}
                    count={getPageCount(tabValue)}
                    page={paginationCompany.page}
                    onChange={onCompanyPageChange}
                    showFirstButton showLastButton
                    className={classes.pagination}
                  />
                </div>
              }
              {/* <Grid container className={classes.grid} spacing={2}>
                <Grid item xs={12}  >
                  <Grid container justify="center" spacing={2} >

                    {tabValue === 'all' &&
                      !isLoading &&
                      pageData.results &&
                      pageData.results.length > 1 &&
                      renderAllProducts(pageData.results)
                    }
                    {tabValue === 'brands' &&
                      !isLoading &&
                      pageData.results &&
                      pageData.results.length > 1 &&
                      renderBrands(pageData.results)
                    }
                    {tabValue === 'categories' &&
                      !isLoading &&
                      pageData.results &&
                      pageData.results.length > 1 &&
                      renderCategories(pageData.results)
                    }
                    {tabValue === 'companies' &&
                      !isLoading &&
                      pageData.results &&
                      pageData.results.length > 1 &&
                      renderCompanies(pageData.results)
                    }
                  </Grid>
                </Grid>
              </Grid> */}

            </Paper>

          </div>
          {/* </Paper> */}
        </div>
      </Container>
    </div>
  );

};
const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(Home);
