import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Snackbar, FormControl, InputLabel, Checkbox, IconButton, Menu, MenuItem,Grid, CircularProgress} from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
// import {ImageActions } from '../../models';
import { Toast, enqueueSnackbar, ToastError } from '../../../shared/components/Notifier';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// import { GlassMagnifier } from 'react-image-magnifiers'

interface ImageProps {
  mediaUrls : []
  mediaData: any
  handleUploadClick: (event)=> void
  showDefaultMedia: any
  itemData: any
  defaultMediaData: any
  userUploadedMedia: any
  handleSelectedMediaForAction: (data) => void
  selectedMediaForAction: any
  handleMenuItemClick: (event, index)=> void
  optionsBtnAnchorEl: any
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      mediaList: {
        position: 'relative',
        padding: '5px'
      },
      row: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
      },
      rowCenter: {
        minHeight: 150,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      },

      dnd :{
        border: '1px solid black', 
        display:'flex',
        flexWrap:'wrap', 
        color: 'black', 
        padding: 40,
        borderStyle:'dashed',
        justifyContent: 'center'

      }
    }),
);


let actionOptions = ['Default', 'Inactive', 'Delete']

const Image = (props:any) => {
  const classes = useStyles({});
  const [optionsBtnAnchorEl, setOptionsBtnAnchorEl] = useState(props.optionsBtnAnchorEl)
  const [selectedActionIndex, setSelectedActionIndex] = useState(null)
  

  const { 
    itemData ,mediaUrls, handleUploadClick, showDefaultMedia, 
    defaultMediaData, handleSelectedMediaForAction,userUploadedMedia,
    handleSelectedMedia, userSelectedMedia, onFileSelectChangeHandler,
    mediaData,selectedMediaForAction , handleMenuItemClick,
    fileInputRef
  } = props


  useEffect(()=>{
    setOptionsBtnAnchorEl(props.optionsBtnAnchorEl)
  },[props.optionsBtnAnchorEl])
  


  // useEffect(()=>{
  //   console.log("SELECTED-MEDIA",selectedMediaForAction.filter(media=>media.checked === true))
  //   console.log("showDefaultMEDIA", showDefaultMedia)

  //   console.log("DefaultMEdia",defaultMediaData )

  // },[selectedMediaForAction, showDefaultMedia])


  const getImageList = () => {
    let imgArr = []
    for (let i = 0; i < mediaData.length; i++) {
      imgArr.push(
        <div>
          <div className={classes.mediaList} style={{ border: userSelectedMedia ? userSelectedMedia === mediaData[i].media_core.source ? '1px solid #007bff' : '' : '' }}>
              <img
                id={String(i)}
                key={`${i}`}
                src={mediaData.length > 0 ? mediaData[i].media_core.source : null}
                style={{ objectFit:'contain', height: '65px', width: '75px', opacity: mediaData[i].is_inactive ? '0.5' : null }}
                onClick={()=>handleSelectedMedia(mediaData[i].media_core.source)}
              />
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Checkbox
              checked={mediaData[i].checked}
              onChange={() => handleSelectedMediaForAction(mediaData[i])}
            />
          </div> */}
        </div>

      )
    }
    return imgArr
  }

  const handleMoreOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOptionsBtnAnchorEl(event.currentTarget);
  };



  const handleCloseMoreOptions = () => {
    setOptionsBtnAnchorEl(null);
  }



  // const getActionMenu = () => {
  //   if (selectedMediaForAction.filter(media => media.checked === true).length > 0) {
  //     return (
  //       <>
  //       <Menu
  //         anchorEl={optionsBtnAnchorEl}
  //         keepMounted={false}
  //         open={Boolean(optionsBtnAnchorEl)}
  //         onClose={handleCloseMoreOptions}
  //         PaperProps={{
  //           style: {
  //             transform: 'translateX(10px) translateY(50px)',
  //             left:'875px',
  //             top:'407px'

  //           },
  //         }}
  //       >
  //         {actionOptions.map((actionOption, index) => (
  //           <MenuItem
  //             key={actionOption}
  //             disabled={selectedMediaForAction && selectedMediaForAction.filter(media => media.checked === true).length === 1 ? false : index === 0}
  //             selected={index === selectedActionIndex}
  //             onClick={(event) => handleMenuItemClick(event, index, actionOption)}
  //           >
  //             {actionOption}
  //           </MenuItem>
  //         ))}

  //       </Menu>
  //       </>
  //     )
  //   }
  //   return null
  // }

  // const onTargetClick = () => {
  //   fileInputRef.current.click()
  // }

  const getDefaultImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };



  return(
    <div>
      {showDefaultMedia &&
        <div style={{textAlign:'end'}}>
          <img
            alt={itemData ? itemData.name ? itemData.name : '' : ''}
            src={getDefaultImagePath()}
            style={{ height: '250px', width: '250px' }}
          />
        </div>
      }
      {!showDefaultMedia &&
        <Grid container className={classes.row}>
          <div style={{ justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
              <div style={{ display: 'flex' }}>
                {mediaUrls && mediaUrls.length > 0 && getImageList()}
              </div>
              {/* <div >
                {
                  <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <IconButton color="primary" onClick={handleUploadClick}>
                      <AddAPhotoIcon
                      />
                    </IconButton>
                    {
                      (!showDefaultMedia && selectedMediaForAction.filter(media => media.checked === true).length > 0) ?
                        <IconButton color="primary" style={{marginTop:'5px'}} onClick={handleMoreOptionsClick}>
                          <MoreVertIcon />
                        </IconButton>
                        : null
                    }
                  </div>

                }
              </div> */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: '10px', justifyContent: 'center' }}>

              {!showDefaultMedia && userSelectedMedia ?
                <div style={{ textAlign: "center" }} >
                  <img
                    id={'userselected-img'}
                    alt={itemData ? itemData.name ? itemData.name : '' : ''}
                    src={userSelectedMedia ? userSelectedMedia : null}
                    style={{ objectFit:'contain', height: '225px', width: '225px' }}
                  />
                </div> :
                defaultMediaData ?
                  <div style={{ textAlign: "center" }} >

                    <img
                      id={'defaultMediaData-img'}
                      alt={itemData ? itemData.name ? itemData.name : '' : ''}
                      src={defaultMediaData ? defaultMediaData[0].media_core.source : null}
                      style={{ objectFit:'contain',height: '225px', width: '225px' }}
                    />
                  </div>
                  : null
              }
              {/* {
                !userUploadedMedia &&
                <input type="file" name="file"
                  multiple
                  onChange={onFileSelectChangeHandler}
                  id="import-media"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*" />
              }
              {getActionMenu()} */}

            </div>
          </div>
        </ Grid >
      }
    </div>

  )





};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};
export default connect(null, mapDispatchToProps)(Image);
